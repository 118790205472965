import whenReady from '../utils/whenReady';

whenReady().then(() => {
  const $$electionSlide = document.querySelectorAll('[data-app="dpa-electionslive"]');

  if ($$electionSlide.length > 0) {
    const $dpaScript = document.createElement('script');

    $dpaScript.type = 'text/javascript';
    $dpaScript.src = 'https://core.dpa-infocom.net/js/dpa.js';

    document.head.appendChild($dpaScript);
  }
});
