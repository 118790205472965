import { on } from 'delegated-events';

on('click', '.tab-bar__option', (event) => {
  const { currentTarget: $option } = event;
  const $scroller = $option.closest<HTMLElement>('.tab-bar__scroller');

  if ($scroller) {
    $scroller.scrollTo({
      left: $option.offsetLeft - $scroller.offsetLeft,
      behavior: 'smooth',
    });
  }
});
