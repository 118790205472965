import { on } from 'delegated-events';
import abort from '../../../javascripts/utils/abort';
import moveFocus from '../../../javascripts/utils/moveFocus';

on('dropdown:change', '.election-slide__results', (event) => {
  const $electionSlide = event.currentTarget.closest<HTMLElement>('.election-slide') ?? abort();
  const type = event.currentOption?.dataset.value;

  if (type) {
    const $next = $electionSlide.querySelector<HTMLElement>(
      `.election-slide__embed:is([data-election-slide-type="${type}"])`,
    );

    if ($next) {
      $electionSlide.querySelectorAll<HTMLElement>('.election-slide__embed').forEach(($embed) => {
        $embed.hidden = true;
      });

      $next.hidden = false;
      moveFocus($next);
    }
  }
});
