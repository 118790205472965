import getIndexByLetter from '../../../javascripts/utils/getIndexByLetter';
import invisibleFocus from '../../../javascripts/utils/invisibleFocus';
import abort from '../../../javascripts/utils/abort';
import isScrollable from '../../../javascripts/utils/isScrollable';
import isElementInView from '../../../javascripts/utils/isElementInView';
import moveFocus from '../../../javascripts/utils/moveFocus';
import onLostFocus from '../../../javascripts/utils/onLostFocus';
import createFloating from '../../../javascripts/utils/createFloating';
import DropdownEvent from '../../../javascripts/events/DropdownEvent';

enum DropdownAction {
  Close = 0,
  CloseSelect = 1,
  First = 2,
  Last = 3,
  Next = 4,
  Open = 5,
  PageDown = 6,
  PageUp = 7,
  Previous = 8,
  Select = 9,
  Type = 10,
}

const getActionFromKey = (event: KeyboardEvent, menuOpen: boolean): DropdownAction | null => {
  const { key, altKey, ctrlKey, metaKey } = event;
  const openKeys = ['ArrowDown', 'ArrowUp', 'Enter', ' '];

  if (!menuOpen && openKeys.includes(key)) {
    return DropdownAction.Open;
  }

  if (key === 'Home') {
    return DropdownAction.First;
  }

  if (key === 'End') {
    return DropdownAction.Last;
  }

  if (key === 'Backspace' || key === 'Clear' || (key.length === 1 && key !== ' ' && !altKey && !ctrlKey && !metaKey)) {
    return DropdownAction.Type;
  }

  if (menuOpen) {
    if (key === 'ArrowUp' && altKey) {
      return DropdownAction.CloseSelect;
    }

    if (key === 'ArrowDown' && !altKey) {
      return DropdownAction.Next;
    }

    if (key === 'ArrowUp') {
      return DropdownAction.Previous;
    }

    if (key === 'PageUp') {
      return DropdownAction.PageUp;
    }

    if (key === 'PageDown') {
      return DropdownAction.PageDown;
    }

    if (key === 'Escape') {
      return DropdownAction.Close;
    }

    if (key === 'Enter' || key === ' ') {
      return DropdownAction.CloseSelect;
    }
  }

  return null;
};

const maintainScrollVisibility = ($activeElement: HTMLElement, $scrollParent: HTMLElement): void => {
  const { offsetHeight, offsetTop } = $activeElement;
  const { offsetHeight: parentOffsetHeight, scrollTop } = $scrollParent;

  const isAbove = offsetTop < scrollTop;
  const isBelow = offsetTop + offsetHeight > scrollTop + parentOffsetHeight;

  if (isAbove) {
    $scrollParent.scrollTo(0, offsetTop);
  } else if (isBelow) {
    $scrollParent.scrollTo(0, offsetTop - parentOffsetHeight + offsetHeight);
  }
};

class Dropdown {
  defaultToggleText: string;

  #type: string;
  #$dropdown: HTMLElement;
  #$toggle: HTMLButtonElement;
  #$toggleText: HTMLElement;
  #$menu: HTMLElement;
  #$arrow: HTMLElement;
  #$$option: HTMLElement[] = [];
  #$currentOption: HTMLElement | null = null;
  #$selectedOption: HTMLElement | null = null;
  #$backedInput: HTMLInputElement | null;
  #currentIndex: number = 0;
  #selectedIndex: number | null = null;
  #searchString = '';
  #searchTimeout: number | undefined;
  #ignoreBlur = false;
  #open = false;
  #cleanup: undefined | (() => void) = undefined;
  #lostFocus: undefined | (() => void) = undefined;

  constructor($dropdown: HTMLElement) {
    this.#$dropdown = $dropdown;
    this.#type = $dropdown.dataset.dropdownType ?? abort();
    this.#$toggle = this.#$dropdown.querySelector<HTMLButtonElement>('.dropdown__toggle') ?? abort();
    this.#$toggleText = this.#$toggle.querySelector<HTMLElement>('.dropdown__toggle-text') ?? abort();
    this.defaultToggleText = this.#$toggleText.innerText;
    this.#$menu = this.#$dropdown.querySelector<HTMLElement>('.dropdown__menu') ?? abort();
    this.#$backedInput = this.#$dropdown.querySelector<HTMLInputElement>('input[type=hidden]');
    this.#$arrow = this.#$dropdown.querySelector<HTMLElement>('.dropdown__arrow') ?? abort();

    // Add handlers for toggle
    this.#$toggle.addEventListener('click', this.#onToggleClick.bind(this));
    this.#$menu.addEventListener('mousedown', this.#onOptionMousedown.bind(this));

    // Add custom attributes and handlers for select mode
    if (this.#type === 'select') {
      // Add aria-roles and add tab index to button
      this.#$toggle.setAttribute('role', 'combobox');
      this.#$toggle.setAttribute('aria-haspopup', 'listbox');

      // Add aria-roles and add tab index to options
      this.#$menu.setAttribute('role', 'listbox');
      this.#$menu.setAttribute('tabindex', '-1');

      // Add event handlers
      this.#$toggle.addEventListener('blur', this.#onSelectBlur.bind(this));
      this.#$toggle.addEventListener('keydown', this.#onSelectKeyDown.bind(this));
      this.#$menu.addEventListener('click', this.#onSelectOptionClick.bind(this));

      // Select default
      const selectedIndex = this.#$$option.findIndex(($option) => $option.getAttribute('aria-selected') === 'true');
      if (selectedIndex >= 0) {
        this.#selectedIndex = selectedIndex;
        this.#$selectedOption = this.#$$option[selectedIndex];
      }
    }

    // Add custom attributes and handlers for menu mode
    if (this.#type === 'menu') {
      this.#$toggle.addEventListener('keydown', this.#onMenuKeyDown.bind(this));
      this.#$menu.addEventListener('keydown', this.#onMenuKeyDown.bind(this));
      this.#$menu.addEventListener('click', this.#onMenuOptionClick.bind(this));
      this.#currentIndex = -1;
    }

    // Init options
    this.update();
  }

  update() {
    this.#$$option = Array.from(this.#$menu.querySelectorAll<HTMLElement>('.dropdown__option')).filter(
      ($option) => !$option.hidden,
    );

    if (this.#type === 'select') {
      this.#$toggle.setAttribute('aria-activedescendant', this.#open ? (this.#$currentOption?.id ?? '') : '');
    }
  }

  select(index: number) {
    this.#selectedIndex = index;
    this.#$selectedOption = this.#$$option[index];

    if (this.#type === 'select') {
      this.#$backedInput?.setAttribute('value', this.#$selectedOption.getAttribute('data-value') ?? '');
      this.#$toggleText.innerText = this.#$selectedOption.innerText.trim();

      this.#$$option.forEach(($option) => {
        $option.setAttribute('aria-selected', $option === this.#$selectedOption ? 'true' : 'false');
      });
    }

    if (this.#type === 'menu') {
      this.#$$option.forEach(($option) => {
        if ($option === this.#$selectedOption) {
          $option.setAttribute('aria-current', 'page');
        } else {
          $option.removeAttribute('aria-current');
        }
      });
    }

    this.#$dropdown.dispatchEvent(new DropdownEvent(this.#$selectedOption));
  }

  open() {
    this.toggle(true);
  }

  close() {
    this.toggle(false);
  }

  async toggle(open: boolean | null = null, callFocus = true) {
    if (this.#open === open) {
      return;
    }

    this.#open = open === null ? !this.#open : open;
    this.#$toggle.setAttribute('aria-expanded', open ? 'true' : 'false');
    this.#$menu.toggleAttribute('hidden', !open);
    this.update();

    if (this.#open) {
      this.#cleanup = await createFloating({
        $reference: this.#$toggle,
        $floating: this.#$menu,
        $arrow: this.#$arrow,
        placements: ['top', 'bottom'],
        onCalculate: (result) => {
          const { placement, x, y, middlewareData } = result;

          this.#$menu.dataset.placement = placement;
          this.#$menu.style.insetInlineStart = `${x}px`;
          this.#$menu.style.insetBlockStart = `${y}px`;

          if (middlewareData.arrow) {
            const { x: arrowX } = middlewareData.arrow;

            this.#$arrow.style.insetInlineStart = arrowX ? `${arrowX}px` : '';

            if (placement.startsWith('bottom')) {
              this.#$arrow.style.insetBlock = '0px auto';
              this.#$arrow.style.transform = 'translate(0, -100%)';
            } else {
              this.#$arrow.style.insetBlock = 'auto 0px';
              this.#$arrow.style.transform = 'rotate(180deg) translate(0, -100%)';
            }
          }
        },
      });

      this.#lostFocus = onLostFocus(this.#$dropdown, () => {
        this.toggle(false, false);
      });
    } else {
      this.#cleanup?.();
      this.#lostFocus?.();

      if (this.#type === 'menu') {
        this.#currentIndex = -1;
      }
    }

    if (callFocus) {
      invisibleFocus(this.#$toggle);
    }
  }

  #switchToOption(index: number) {
    this.#currentIndex = index;
    this.#$currentOption = this.#$$option[index];
    this.#$toggle.setAttribute('aria-activedescendant', this.#$currentOption.id ?? '');

    this.#$$option.forEach(($option) => {
      $option.setAttribute('data-selected', $option === this.#$currentOption ? 'true' : 'false');
    });

    if (isScrollable(this.#$menu)) {
      maintainScrollVisibility(this.#$currentOption, this.#$menu);
    }

    if (!isElementInView(this.#$currentOption)) {
      this.#$currentOption.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }

    this.#$dropdown.dispatchEvent(new DropdownEvent(this.#$currentOption));
  }

  #focusToOption(index: number) {
    this.#currentIndex = index;
    this.#$currentOption = this.#$$option[index];

    moveFocus(this.#$currentOption);

    if (isScrollable(this.#$menu)) {
      maintainScrollVisibility(this.#$currentOption, this.#$menu);
    }

    if (!isElementInView(this.#$currentOption)) {
      this.#$currentOption.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }

    this.#$dropdown.dispatchEvent(new DropdownEvent(this.#$currentOption));
  }

  #getSearchString(char: string) {
    if (typeof this.#searchTimeout === 'number') {
      window.clearTimeout(this.#searchTimeout);
    }

    this.#searchTimeout = window.setTimeout(() => {
      this.#searchString = '';
    }, 500);

    this.#searchString += char;

    return this.#searchString;
  }

  #onSelectBlur() {
    if (this.#ignoreBlur) {
      this.#ignoreBlur = false;
      return;
    }

    if (this.#open) {
      if (this.#selectedIndex) {
        this.select(this.#selectedIndex);
      }

      this.toggle(false, false);
    }
  }

  #onToggleClick() {
    invisibleFocus(this.#$dropdown);
    this.toggle(!this.#open, false);
  }

  #onSelectKeyDown(event: KeyboardEvent) {
    const { key } = event;
    const action = getActionFromKey(event, this.#open);

    // eslint-disable-next-line default-case
    switch (action) {
      case DropdownAction.Last:
      case DropdownAction.First:
        event.preventDefault();
        this.toggle(true);
        this.#switchToOption(this.#getUpdatedIndex(action));
        break;

      case DropdownAction.Next:
      case DropdownAction.Previous:
      case DropdownAction.PageUp:
      case DropdownAction.PageDown:
        event.preventDefault();
        this.#switchToOption(this.#getUpdatedIndex(action));
        break;

      case DropdownAction.CloseSelect:
        event.preventDefault();
        this.select(this.#currentIndex);
        this.toggle(false);
        break;

      case DropdownAction.Close:
        event.preventDefault();
        this.toggle(false);
        break;

      case DropdownAction.Type:
        this.#onComboType(key);
        break;

      case DropdownAction.Open:
        event.preventDefault();
        this.toggle(true);
        break;
    }
  }

  #onMenuKeyDown(event: KeyboardEvent) {
    const action = getActionFromKey(event, this.#open);

    if (action === DropdownAction.Close) {
      event.preventDefault();
      this.toggle(false);
      return;
    }

    if (this.#open) {
      // eslint-disable-next-line default-case
      switch (action) {
        case DropdownAction.Last:
        case DropdownAction.First:
        case DropdownAction.Next:
        case DropdownAction.Previous:
          event.preventDefault();
          this.#focusToOption(this.#getUpdatedIndex(action));
          break;
      }
    }
  }

  #onComboType(letter: string) {
    this.toggle(true);

    const searchString = this.#getSearchString(letter);
    const searchIndex = getIndexByLetter(
      this.#$$option.map(($option) => $option.innerText),
      searchString,
      (this.#currentIndex ?? 0) + 1,
    );

    if (searchIndex >= 0) {
      this.#switchToOption(searchIndex);
    } else {
      window.clearTimeout(this.#searchTimeout);
      this.#searchString = '';
    }
  }

  #onSelectOptionClick(event: MouseEvent) {
    const { target: $target } = event;
    const $clickedOption = ($target as HTMLElement | undefined)?.closest('.dropdown__option');
    const index = this.#$$option.findIndex(($option) => $option === $clickedOption);

    if (index >= 0) {
      event.preventDefault();

      this.#switchToOption(index);
      this.select(index);
      this.toggle(false);
    }
  }

  #onMenuOptionClick(event: MouseEvent) {
    const { target: $target } = event;
    const $clickedOption = ($target as HTMLElement | undefined)?.closest('.dropdown__option');
    const index = this.#$$option.findIndex(($option) => $option === $clickedOption);

    if (index >= 0) {
      this.select(index);
      this.toggle(false);
    }
  }

  #onOptionMousedown() {
    this.#ignoreBlur = true;
  }

  #getUpdatedIndex(action: DropdownAction | null = null) {
    const max = this.#$$option.length - 1;
    const pageSize = 10;

    switch (action) {
      case DropdownAction.First:
        return 0;
      case DropdownAction.Last:
        return max;
      case DropdownAction.Previous:
        return Math.max(0, this.#currentIndex - 1);
      case DropdownAction.Next:
        return Math.min(max, this.#currentIndex + 1);
      case DropdownAction.PageUp:
        return Math.max(0, this.#currentIndex - pageSize);
      case DropdownAction.PageDown:
        return Math.min(max, this.#currentIndex + pageSize);
      default:
        return this.#currentIndex;
    }
  }
}

const dropdownInstances = new Map<HTMLElement, Dropdown>();

document.querySelectorAll<HTMLElement>('.dropdown').forEach(($dropdown) => {
  dropdownInstances.set($dropdown, new Dropdown($dropdown));
});

export const getDropdownInstance = ($dropdown: HTMLElement): Dropdown =>
  dropdownInstances.get($dropdown) ??
  dropdownInstances.set($dropdown, new Dropdown($dropdown)).get($dropdown) ??
  abort();

export default Dropdown;
